export const i18n_en = {
  "Chargement...": "Loading...",
  "Votre session semble avoir expirée, merci de vous reconnecter, svp": "Your session seems to have expired, please log in again.",
  "Retourner sur la page de connexion": "Return to login page",
  "2023 | Propriété exclusive de": "2023 | Exclusive property of",
  "A propos": "About",
  "Notre philosophie": "Our philosophy",
  "Contactez-nous !": "Contact us!",
  "Vue rapide": "Quick view",
  "Prochaines échéances": "Upcoming deadlines",
  "Mises à jour": "Updates",
  "Paramètres du compte": "Account settings",
  "Déconnexion": "Log out",
  "Retour": "Back",
  "RECHERCHE DE DISPONIBILITéS": "Availability search",
  "ADMINISTRATION DU SITE": "Site administration",
  "Mot de passe oublié ?": "Forgot password?",
  "Veuillez nous contacter au": "Please contact us at",
  "afin de procéder à la vérification de votre identité": "to verify your identity",
  "et la réinitilisation de votre mot de passe.": "and reset your password.",
  "Retour à la page de connexion": "Return to login page",
  "Connexion à Still'OnStock": "Login to Still'OnStock",
  "Vous avez déjà un compte ?": "Already have an account?",
  "Se connecter via Linkedin": "Log in with Linkedin",
  "OU": "OR",
  "Identifiant": "Username",
  "Mot de passe": "Password",
  "Se connecter": "Log in",
  "Vous n'avez pas encore de compte ?": "Don't have an account yet?",
  "Créer un compte utilisateur": "Create a user account",
  "Connexion établie !": "Connection established!",
  "Bienvenue": "Welcome",
  "sur votre espace Still'OnStock !": "to your Still'OnStock space!",
  "Accèder à Still'OnStock": "Access Still'OnStock",
  "Votre compte est désactivé, veuillez contacter Stillnetwork afin de pouvoir vous reconnecter.": "Your account is deactivated, please contact Stillnetwork to reactivate it.",
  "L'identifiant doit être un email valide": "Username must be a valid email",
  "L'identifiant est obligatoire": "Username is required",
  "Le mot de passe est obligatoire": "Password is required",
  "Il semble y avoir une erreur, veuillez vérifier vos identifiants de connexion.": "There seems to be an error, please check your login credentials.",
  "Création de compte utilisateur": "User account creation",
  "Prénom": "First Name",
  "Nom": "Last Name",
  "Société": "Company",
  "Téléphone": "Phone",
  "Email": "Email",
  "Confirmer le mot de passe": "Confirm Password",
  "Vous acceptez les": "You accept the",
  "Termes et conditions": "Terms and Conditions",
  "Créer votre compte Still'OnStock": "Create your Still'OnStock account",
  "Veuillez patienter...": "Please wait...",
  "Le prénom est requis": "First name is required",
  "Le nom est requis": "Last name is required",
  "Numéro valide": "Valid number",
  "Numéro non valide": "Invalid number",
  "L'email doit contenir au moins 4 caractères": "Email must be at least 4 characters",
  "Email non valide": "Invalid email",
  "L'email est requis": "Email is required",
  "Email valide": "Valid email",
  "Veuillez utiliser une adresse professionnelle": "Please use a professional email address",
  "Le mot de passe doit contenir au moins 4 caractères": "Password must be at least 4 characters",
  "Le mot de passe est requis": "Password is required",
  "Erreur lors de la confirmation du mot de passe": "Error in password confirmation",
  "Le mot de passe doit correspondre": "Passwords must match",
  "Veuillez accepter nos conditions": "Please accept our terms and conditions",
  "Votre compte a bien été créé !": "Your account has been successfully created!",
  "Oups...": "Oops...",
  "Retourner à l'accueil": "Return to homepage",
  "Liste des Producteurs et des Fournisseurs": "List of Producers and Suppliers",
  "Aucune ligne à afficher": "No lines to display",
  "Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.": "There are no lines to display that match your search criteria.",
  "Modifier": "Edit",
  "Aucune ligne à afficher.": "No lines to display.",
  "Collectivité": "Community",
  "Personne": "Person",
  "Famille": "Family",
  "Type inconnu": "Unknown type",
  "Gestion des utilisateurs": "User management",
  "Historique des recherches": "Search history",
  "Historique des reservations": "Reservation history",
  "Créer un utilisateur": "Create a user",
  "Identité": "Identity",
  "Coordonnées": "Contact Information",
  "Paramètrage": "Configuration",
  "Lien vers un AM Stillnetwork": "Link to a Stillnetwork AM",
  "Tant qu'aucun lien vers un utilisateur still n'est sélectionnée, chaque action de cet utilisateur sera envoyé sur le mail générique.": "As long as no link to a Stillnetwork user is selected, each action of this user will be sent to the generic email.",
  "Annuler": "Cancel",
  "l'utilisateur": "User",
  "Il n'existe aucun historique de recherche sur cet utilisateur.": "There is no search history for this user.",
  "Fermer": "Close",
  "Spécifier le Prénom": "Specify First Name",
  "Spécifier le nom": "Specify Last Name",
  "Spécifier la société": "Specify Company",
  "Spécifier le Téléphone": "Specify Phone",
  "Spécifier le Mail *": "Specify Email *",
  "Utilisateur adminstrateur": "Administrator User",
  "Utilisateur Stillnetwork": "Stillnetwork User",
  "Compte désactivé": "Account Deactivated",
  "Selection AM Stillnetwork": "Select Stillnetwork AM",
  "Historique de recherche de l'utilisateur": "User's Search History",
  "Aucun historique à afficher.": "No history to display.",
  "Créer": "Create",
  "d'un utilisateur": "of a user",
  "Création": "Creation",
  "Modification": "Modification",
  "Le champ Prénom est obligatoire": "First Name field is required",
  "Le champ Nom est obligatoire": "Last Name field is required",
  "Le champ Email est obligatoire": "Email field is required",
  "Utilisateur modifié avec succès !": "User modified successfully!",
  "Utilisateur créé avec succès !": "User created successfully!",
  "Erreur": "Error",
  "Succès": "Success",
  "Recherches effectuées par les utilisateurs": "Searches performed by users",
  "AM Still':": "AM Still':",
  "Aucune recherche effectuée": "No searches performed",
  "Reservations effectuées par les utilisateurs": "Reservations made by users",
  "Afficher la réservation": "Show reservation",
  "Commentaire :": "Comment:",
  "Liste des utilisateurs": "List of users",
  "Adminstrateur": "Administrator",
  "Historique": "History",
  "Oui, réinitialiser le mot de passe": "Yes, reset the password",
  "Non": "No",
  "Réinitialiser le mot de passe et renvoyer un mail à l'utilisateur ?": "Reset the password and send an email to the user?",
  "Mot de passe réinitialisé avec succès !": "Password reset successfully!",
  "LECTURE SEULE": "READ ONLY",
  "Export Arkheïa": "Export Arkheïa",
  "Export XML": "Export XML",
  "Export PDF": "Export PDF",
  "Export JSON": "Export JSON",
  "Liste des articles": "List of articles",
  "Dates :": "Dates:",
  "/ DUA :": "/ DUA:",
  "Observation": "Observation",
  "Oui": "Yes",
  "Numéro d'ordre": "Order Number",
  "Libellé": "Label",
  "Dates": "Dates",
  "N° d'ordre": "Order Number",
  "Titre": "Title",
  "Dates extrêmes": "Extreme Dates",
  "Métrage Linéaire": "Linear Meterage",
  "ml": "m",
  "DUA spécifique": "Specific DUA",
  "Années": "Years",
  "A spécifier si différent du bordereau": "Specify if different from the manifest",
  "Observations": "Observations",
  "Référence": "Reference",
  "réglementaire": "regulatory",
  "Créer un nouvel article": "Create a new article",
  "Exporter le détail du bordereau": "Export manifest details",
  "Oui, Supprimer": "Yes, Delete",
  "Confirmez-vous la suppression de la ligne ?": "Confirm the deletion of the line?",
  "Sans date": "No date",
  "Année de début": "Start year",
  "Année de fin": "End year",
  "Saisir": "Enter",
  "DUA": "DUA",
  "Facultatif...": "Optional...",
  "Modifier l'article": "Edit the article",
  "Créer l'article": "Create the article",
  "d'un article": "of an article",
  "de versement": "of deposit",
  "d'élimination": "of elimination",
  "Article supprimé de la liste.": "Article removed from the list.",
  "L'année début semble être incorrecte. Veuillez vérifier votre saisie, svp": "The start year seems to be incorrect. Please check your input, please.",
  "L'année de fin semble être incorrecte. Veuillez vérifier votre saisie, svp": "The end year seems to be incorrect. Please check your input, please.",
  "L'année de fin ne peut être inférieure à l'année de début. Veuillez vérifier votre saisie, svp": "The end year cannot be earlier than the start year. Please check your input, please.",
  "Le métrage linéaire doit être un nombre ou vide. Veuillez vérifier votre saisie, svp": "The linear meterage must be a number or empty. Please check your input, please.",
  "Article modifié avec succès !": "Article successfully modified!",
  "Article créé avec succès !": "Article successfully created!",
  "Description du bordereau": "Manifest description",
  "Veuillez sélectionner un type de versement ci-dessous.": "Please select a type of deposit below.",
  "PAPIER": "PAPER",
  "DOSSIERS PHYSIQUES": "PHYSICAL FOLDERS",
  "NUMERIQUES": "DIGITAL",
  "FICHIER NUMÉRIQUES": "DIGITAL FILE",
  "MIXTE": "MIXED",
  "PAPIER ET NUMÉRIQUES": "PAPER AND DIGITAL",
  "Versement de type : Papier": "Deposit type: Paper",
  "Nombre total d'articles": "Total number of articles",
  "Nombre de boîtes": "Number of boxes",
  "boites": "boxes",
  "Métrage": "Meterage",
  "Type de support": "Media type",
  "Format": "Format",
  "Versement de type : Fichier numériques": "Deposit type: Digital File",
  "Nombre de fichier": "Number of files",
  "Volumétrie": "Volume",
  "Support de stockage": "Storage medium",
  "Description sommaire": "Summary description",
  "Articles extrêmes": "Extreme articles",
  "Années extrêmes": "Extreme years",
  "Description": "Description",
  "Calculer le nombre total d'article du bordereau": "Calculate the total number of articles in the manifest",
  "Calculer le métrage linaire des articles du bordereau": "Calculate the linear meterage of the articles in the manifest",
  "Papier": "Paper",
  "Photo": "Photo",
  "Plan": "Plan",
  "Autre": "Other",
  "A4": "A4",
  "A3": "A3",
  "CD / DVD": "CD / DVD",
  "Clef USB": "USB Key",
  "Disque dur externe": "External Hard Drive",
  "Bureautique": "Office",
  "PDF": "PDF",
  "Image": "Image",
  "Vidéo": "Video",
  "Son": "Audio",
  "Calculer les années extrêmes": "Calculate extreme years",
  "Ko": "KB",
  "Mo": "MB",
  "Go": "GB",
  "To": "TB",
  "Po": "PB",
  "Traitement du bordereau": "Manifest processing",
  "Type de bordereau": "Manifest type",
  "Basculer sur un bordereau": "Switch to a manifest",
  "Nombre d'articles à éliminer": "Number of articles to eliminate",
  "Années / Dates extrêmes": "Extreme Years / Dates",
  "Mot-clés": "Keywords",
  "Nouveau Mot-clé": "New Keyword",
  "Référence réglementaire": "Regulatory reference",
  "Délai et durée": "Deadline and duration",
  "Délai de communicabilité maximum": "Maximum communicability deadline",
  "an(s)": "year(s)",
  "Communicabilité totalité du versement": "Total communicability of the deposit",
  "Identification du service": "Service identification",
  "Rédacteur": "Editor",
  "Archiviste": "Archivist",
  "Chef de service": "Head of service",
  "Aucun Chef de service": "No Head of service",
  "Direction des archives": "Archives department",
  "Aucune direction des archives": "No archives department",
  "Conservation intégrale": "Complete preservation",
  "Conservation partielle": "Partial preservation",
  "Elimination": "Elimination",
  "Spécifier ici les références réglementaires, si existante...": "Specify regulatory references here, if applicable...",
  "Choix de la date": "Date selection",
  "Selection": "Selection",
  "PDF du bordereau": "Manifest PDF",
  "BORDEREAU": "MANIFEST",
  "Souhaitez-vous réellement": "Do you really wish to",
  "Confirmation": "Confirmation",
  "Oui,": "Yes,",
  "Une notification sera envoyée à": "A notification will be sent to",
  "pour traitement du bordereau.": "for manifest processing.",
  "En cours": "In progress",
  "supprimer ce bordereau": "delete this manifest",
  "demander la validation auprès de l'archiviste": "Request validation from the archivist",
  "En attente validation archiviste": "Awaiting archivist validation",
  "rejeter ce bordereau": "Reject this manifest",
  "valider ce bordereau": "Validate this manifest",
  "En attente visa chef de service": "Awaiting Head of Service's visa",
  "refuser ce bordereau": "Refuse this manifest",
  "apposer un VISA de validation sur ce bordereau": "Apply a validation VISA to this manifest",
  "En attente visa direction archive": "Awaiting archive department visa",
  "Terminé": "Completed",
  "Supprimé": "Deleted",
  "Le champ Fournisseur/ Producteur est obligatoire": "The Supplier/Producer field is mandatory",
  "Le champ Archiviste est obligatoire": "The Archivist field is mandatory",
  "Bordereau créé avec succès !": "Manifest created successfully!",
  "Détail du produit": "Product details",
  "Constructeur": "Manufacturer",
  "Catégorie": "Category",
  "Délai usine": "Factory lead time",
  "Sous": "Under",
  "Soit à partir du": "Either starting from",
  "Stock neuf disponible": "New stock available",
  "Livrable sous 48 heures": "Deliverable within 48 hours",
  "Aucun stock neuf": "No new stock available",
  "Stock reconditionné": "Refurbished stock",
  "Livrable sous 5 jours": "Deliverable within 5 days",
  "Livrable sous 9 jours": "Deliverable within 9 days",
  "Livrable sous 1 mois": "Deliverable within 1 month",
  "Distributeurs": "Distributors",
  "Aucun stock disponible chez les distributeurs.": "No stock available from distributors.",
  "Select": "Select",
  "Visuel du produit": "Product visual",
  "Spécificités CISCO": "CISCO Specifics",
  "Sous Groupe CISCO": "CISCO Subgroup",
  "Nom de service": "Service name",
  "Indicateur de configuration": "Configuration indicator",
  "Entité interne": "Internal entity",
  "Type": "Type",
  "inconnu": "unknown",
  "jours": "days",
  "Recherche en cours...": "Search in progress...",
  "Lancer la recherche": "Start the search",
  "Pour rechercher plusieurs références spécifiques, veuillez les séparer par un": "To search for multiple specific references, please separate them with a",
  "point virgule": "semicolon",
  "par exemple :": "for example:",
  "Résultat de votre recherche": "Your search result",
  "résultat": "result",
  "Quantité disponible :": "Available quantity:",
  "Livrable sous 48h": "Deliverable within 48 hours",
  "Aucun stock": "No stock",
  "Délai usine de": "Factory lead time of",
  "Voir le détail du produit": "See product details",
  "Neuf - Livrable sous 48h": "New - Deliverable within 48 hours",
  "Reconditionnés - Voir détails": "Refurbished - See details",
  "Réserver": "Reserve",
  "Détails": "Details",
  "Pour continuer, vous devez vous créer un compte.": "To continue, you must create an account.",
  "C'est simple, rapide, gratuit et sans engagement !": "It's simple, fast, free, and without obligation!",
  "Accèder à tout notre stock produits,": "Access all our product stock,",
  "Veillez à avoir une vue claire sur chaque disponibilité": "Make sure to have a clear view of each availability,",
  "Vérifier les dates de réapprovisionnement,": "Check replenishment dates,",
  "Filtrer la recherche": "Filter the search",
  "Rechercher un ou des produits en saisissant le SKU": "Search for one or more products by entering the SKU",
  "Votre demande de réservation": "Your reservation request",
  "Transmettre votre demande": "Submit your request",
  "Oui, retirer cette référence": "Yes, remove this reference",
  "Commentaires sur votre demande": "Comments on your request",
  "Discussion autour du bordereau": "Discussion about the manifest",
  "Fichier :": "File:",
  "Envoyer": "Send",
  "Ecrire un nouveau message...": "Write a new message...",
  "Exporter": "Export",
  "Détails de votre profil utilisateur": "Your user profile details",
  "Prénom Nom": "First Name Last Name",
  "Téléphone Fixe": "Landline Phone",
  "Votre Service": "Your Department",
  "Profil de connexion": "Connection Profile",
  "Votre adresse Email": "Your Email Address",
  "Veuillez saisir votre adresse Email": "Please enter your Email address",
  "Confirmer votre mot de passe": "Confirm your password",
  "Modifier votre Email": "Change your Email",
  "Le mot de passe doit faire minimum 8 caractères.": "Password must be at least 8 characters long",
  "Mot de passe actuel": "Current password",
  "Nouveau mot de passe": "New password",
  "Confirmation de votre nouveau mot de passe": "Confirm your new password",
  "Modifier votre mot de passe": "Change your password",
  "Changer votre mot de passe": "Change your password",
  "Nom de famille": "Last Name",
  "Service": "Service",
  "Email Address": "Email Address",
  "Le prénom est obligatoire": "First name is required",
  "Le nom est obligatoire": "Last name is required",
  "Mot de passe actuel obligatoire": "Current password is required",
  "Confirmation du nouveau Mot de passe obligatoire": "Confirmation of the new password is required",
  "Les nouveaux mots des passes doivent être identiques": "New passwords must match",
  "Changement de vos informations personnelles correctement effectué !": "Change of your personal information successfully completed!",
  "Ok": "OK",
  "Changement de votre Email correctement effectué !": "Change of your Email successfully completed!",
  "Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp.": "Error during email change. Please check your Email address and password.",
  "Changement de votre mot de passe correctement effectué !": "Change of your password successfully completed!",
  "Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp.": "Error during password change. Please check your information and try again.",
  "Filtrer par": "Filter by",
  "Filtrer la liste": "Filter the list",
  "Aucun filtre...": "No filter...",
  "Exporter et télécharger la liste au format Excel ?": "Export and download the list in Excel format?",
  "Indisponible": "Unavailable",
  "Ref. Arkheia": "Ref. Arkheia",
  "Raison sociale": "Company Name",
  "Adresse": "Address",
  "Date": "Date",
  "Recherche": "Search",
  "Références": "References",
  "Recherchées le": "Searched on",
  "Date de réservation": "Reservation date",
  "Réservation": "Reservation",
  "Créé le": "Created on",
  "Titre / Libellé": "Title / Label",
  "Distributeur": "Distributor",
  "Quantité": "Quantity",
  "Référence produit": "Product reference",
  "Stock Stillnetwork": "Stillnetwork stock",
  "utilisateur": "User",
  "AM": "Account Manager",
  "titre": "Title",
  "Famille de produit": "Product Family",
  "Classe de produit": "Product Class",
  "Sous-classe de produit": "Product Subclass",
  "Rechercher dans la liste": "Search in the list",
  "Retirer de la liste ?": "Remove from the list ?",
  "Il n'y a aucune ligne dans votre demande de réservation": "There are no lines in your reservation request"
}