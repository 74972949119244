import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Bordereau {
  bordSeq: string;
  bordType: string;
}

interface StoreInfo {
  bordereau: Bordereau;
}

@Module
export default class BordereauModule extends VuexModule implements StoreInfo {
  bordereau = {} as Bordereau;

  /**
   * Get Bordereau object for current page
   * @returns object
   */
  get getBordereau(): Bordereau {
    return this.bordereau;
  }

  /**
   * Get breadcrumb array for current page
   * @returns object
   */
  get bordSeq(): string {
    const currentBord = localStorage.getItem("currentBord") || "0";
    //console.log("currentBord",currentBord)
    if (!this.bordereau.bordSeq && currentBord ) return currentBord;
    return this.bordereau.bordSeq;
  }

  /**
   * Get current page title
   * @returns string
   */
  get bordType(): string {
    const currentBordType = localStorage.getItem("currentBordType") || "";
    //console.log("currentBordType",currentBordType)
    if (!this.bordereau.bordType && currentBordType ) return currentBordType;
    return this.bordereau.bordType;
  }

  @Mutation
  [Mutations.SET_BORDEREAU_MUTATION](payload) {
    this.bordereau = payload;
  }

  @Action
  [Actions.SET_CURRENT_BORD](payload) {
    this.context.commit(Mutations.SET_BORDEREAU_MUTATION, payload);
  }
}
